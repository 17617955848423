import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { loadStripe } from '@stripe/stripe-js';
import { CompanyService } from 'src/app/services/api/company.service';
import { SecurityService } from 'src/app/services/security.service';
import { ModalSapComponent } from '../modal/modal-sap/modal-sap.component';
import { ModalStatusComponent } from '../modal/modal-status/modal-status.component';
import { ModalRegisteredComponent } from '../modal/modal-registered/modal-registered.component';
import { ModalMentionsLegalesComponent } from '../modal/modal-mentions-legales/modal-mentions-legales.component';

import { CoachService } from 'src/app/services/api/coach.service';
import { LoadingController } from '@ionic/angular';
import { UserService } from 'src/app/services/api/user.service';
import { FormStorageService } from 'src/app/services/form-storage.service';
import { FormStatus } from 'src/app/models/form-status';
import { CardService } from 'src/app/services/api/card.service';
import { IbanService } from 'src/app/services/api/iban.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
})
export class StatusComponent implements OnInit {
  // SAP
  sap = false
  types = [
    { name: "Auto-entrepreneur", value: "self_employed" },
    { name: "EURL", value: "eurl" },
    { name: "SASU", value: "sasu" },
    // { name: "Autre", value: "other" },
  ]

  // Files variables
  KBISfile: File | null = null;

  public form: FormGroup;
  private loader: HTMLIonLoadingElement;

  formPreview$: FormStatus

  // User infos
  coachInfos;
  userInfos;

  //
  ibanIsInvalid;

  // Patterns
  iban = "^([A-Z]{2}[ \-]?[0-9]{2})(?=(?:[ \-]?[A-Z0-9]){9,30}$)((?:[ \-]?[A-Z0-9]{3,5}){2,7})([ \-]?[A-Z0-9]{1,3})?$"
  bic = "([a-zA-Z]{4})([a-zA-Z]{2})(([2-9a-zA-Z]{1})([0-9a-np-zA-NP-Z]{1}))((([0-9a-wy-zA-WY-Z]{1})([0-9a-zA-Z]{2}))|([xX]{3})|)"

  errorMessages = [];


  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private security: SecurityService,
    private companyService: CompanyService,
    private userService: UserService,
    private location: Location,
    public dialog: MatDialog,
    private coachService: CoachService,
    private loadingController: LoadingController,
    private formStorageService: FormStorageService,
    private cardService: CardService,
    private ibanService: IbanService

  ) { }

  async ngOnInit() {
    this.form = this.formBuilder.group({
      isSAPCertified: ['', [Validators.required]],
      SAPCertificationObtentionDate: ['', []],
      SAPNumber: ['', []],
      registrationNumber: ['', [Validators.required, Validators.minLength(9)]],
      type: ['self_employed', [Validators.required]],
      name: ['', [Validators.required]],
      KBIS: ['', [Validators.required]],
      IBAN: ['', [Validators.required, Validators.pattern(this.iban)
      ]],
      BIC: ['', [Validators.required, Validators.pattern(this.bic)]],
    });

    this.form.get('SAPCertificationObtentionDate').disable()
    this.form.get('SAPNumber').disable()

    // Get user infos
    this.userService.getCurrent().subscribe(res => {
      this.userInfos = res
      this.security.user = res
      console.log('user')
      console.log(this.userInfos)

      this.coachService.get(this.userInfos.profile.coach.uuid).subscribe(res => {
        this.coachInfos = res
        console.log('coach')
        console.log(this.coachInfos)

        if (this.coachInfos.companies.length !== 0) {
          this.companyService.get(this.coachInfos.companies[0].uuid).subscribe((data => {
            console.log(data)
            this.form.patchValue({
              isSAPCertified: (data.isSAPCertified ? '1' : '0'),
              SAPCertificationObtentionDate: data.SAPCertificationObtentionDate.slice(0, 10),
              SAPNumber: data.SAPNumber,
              registrationNumber: data.registrationNumber,
              type: data.type,
              name: data.name,
              IBAN: data.IBAN,
              BIC: data.BIC,
            })

            if (data.isSAPCertified) {
              this.form.get('SAPCertificationObtentionDate').enable()
              this.form.get('SAPNumber').enable()
            }
          }))
        }
      })
    })


    /*** Form values storage when Form is not submitted yet ***/
    // Get the last form values save as an observable
    this.formStorageService.currentFormTwo.subscribe(formTwo => this.formPreview$ = formTwo)

    // Save the last values when user fill form inputs
    this.form.valueChanges.subscribe(value => {
      this.formPreview$ = value
      this.formStorageService.setFormTwo(value)
    })

    // Replace value of the inputs if no key CompanyUuid in the local storage
    if (this.formPreview$) {
      console.log("hello")
      this.form.patchValue({
        isSAPCertified: this.formPreview$.isSAPCertified,
        SAPCertificationObtentionDate: this.formPreview$.SAPCertificationObtentionDate,
        SAPNumber: this.formPreview$.SAPNumber,
        registrationNumber: this.formPreview$.registrationNumber,
        type: this.formPreview$.type,
        name: this.formPreview$.name,
        // KBIS: this.formPreview$.KBIS,
        IBAN: this.formPreview$.IBAN,
        BIC: this.formPreview$.BIC
      })
      if (this.formPreview$.isSAPCertified === '1') {
        console.log('enable inputs')
        this.form.get('SAPCertificationObtentionDate').enable()
        this.form.get('SAPNumber').enable()
      }
    }
    console.log("hello bis")
  }

  formPreview() {
    console.log(this.formPreview$)
  }

  openDialogSap() {
    const dialogRef = this.dialog.open(ModalSapComponent, {
      width: '550px'
    });
  }

  openDialog() {
    console.log("1")
    if (this.form.valid) {
      console.log("2")
      if (this.form.value.isSAPCertified === "1") {
        console.log("3")
        const dialogRef = this.dialog.open(ModalStatusComponent, {
          width: '550px',
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result === 'true') {
            this.onSubmit().then(() => {
              alert('Dismiss here 6')
              this.loader.dismiss().then(() => this.router.navigate(['/coach-inscription-status-bis']))
            })
          }
          if (result === 'false') {
            console.log('hello 1000')
            const dialogRef = this.dialog.open(ModalRegisteredComponent, {
              width: '550px',
            });
            dialogRef.afterClosed().subscribe(result => {
              if (result === 'true') {
                this.onSubmit().then(() => {
                  // this.router.navigate(['/coach-inscription-done'])
                  //----------------
                  const that = this;
                  this.handleForm();
                  //----------------
                })
              }
            });
          }
        });
      } else {
        console.log('hello 1000')
        const dialogRef = this.dialog.open(ModalRegisteredComponent, {
          width: '550px',
        });
        dialogRef.afterClosed().subscribe(result => {
          console.log('Result', result)
          if (result === 'true') {
            this.onSubmit().then((res) => {
              console.log(res)
              // this.router.navigate(['/coach-inscription-done'])
              if (res) {
                const that = this;
                this.handleForm();
              } else {
                return
              }
            })
          }
        });
      }
    }
  }

  openLegal() {
    const dialogRef = this.dialog.open(ModalMentionsLegalesComponent, {
      width: '550px',
      height: '80vh',
      autoFocus: false
    });
  }

  async handleForm() {

    const stripe = await loadStripe(environment.stripe.key);

    console.log('ACCOUNT RESULT')
    const accountResult = await stripe.createToken('account', {
      business_type: 'company',
      company: {
        'name': this.coachInfos.firstName + ' ' + this.coachInfos.lastName,
        'phone': '+33' + this.coachInfos.phone.slice(1),
        'directors_provided': true,
        'executives_provided': true,
        'owners_provided': true,
        "tax_id": "000000000",
        "address": {
          "city": this.coachInfos.profile.address.city,
          "country": "FR",
          "postal_code": this.coachInfos.profile.address.postalCode.toString(),
          "line1": this.coachInfos.profile.address.address
        },
      },
      tos_shown_and_accepted: true,
    });

    console.log("PERSON RESULT")
    const personResult = await stripe.createToken('person', {
      'email': this.userInfos.email,
      'phone': '+33' + this.coachInfos.phone.slice(1),
      "first_name": this.coachInfos.firstName,
      "last_name": this.coachInfos.lastName,
      "address": {
        "city": this.coachInfos.profile.address.city,
        "country": "FR",
        "postal_code": this.coachInfos.profile.address.postalCode.toString(),
        "line1": this.coachInfos.profile.address.address
      },
      "dob": {
        "day": parseInt(this.coachInfos.birthDate.slice(8, 10)),
        "month": parseInt(this.coachInfos.birthDate.slice(5, 7)),
        "year": parseInt(this.coachInfos.birthDate.slice(0, 4)),
      },
      "relationship": {
        "director": true,
        "executive": true,
        "owner": true,
        "representative": true,
        "title": "CEO"
      }
    });
    console.log(accountResult)
    console.log(personResult)
    if (accountResult.token && personResult.token) {
      const body = {
        user: this.userInfos.uuid,
        token_account: accountResult.token.id,
        token_person: personResult.token.id
      }
      console.log("body")
      console.log(body)

      this.cardService.createStripeAccount(body).subscribe(
        res => {
          console.log(res)
          this.verifUser2(res)
        },
        err => {
          console.log(err)
        }
      )
    }
  }

  async verifUser2(event): Promise<any> {
    const stripe = await loadStripe(environment.stripe.key);

    const body = {
      refresh_url: "https://dev.coachs-france.com/",
      return_url: "https://dev.coachs-france.com/coach-inscription-done"
    }

    this.cardService.validation(body).subscribe(
      res => {
        console.log(res);
        let url = res.toString();
        let uri = encodeURI(url);

        this.coachInfos = res;
        console.log(this.coachInfos);

        const bodyIban = {
          iban: 'FR1420041010050500013M02606', // Test IBAN
          currency: "eur",
          titulaire: this.form.value.name,
          address: {
            city: this.userInfos.profile.address.city,
            address: this.userInfos.profile.address.address,
            postalCode: this.userInfos.profile.address.postalCode,
            country: this.userInfos.profile.address.country,
            region: this.userInfos.profile.address.region
          }
        };

        console.log(bodyIban);

        // Move the loader dismissal here, after IBAN creation and redirection
        this.ibanService.createNewIban(bodyIban).subscribe(
          res => {
            console.log(res);
            // Now it's safe to dismiss the loader after the IBAN service and stripe validation are done
            window.location.assign(uri); // Redirect only after the loader is dismissed
            this.loader.dismiss();
          },
          err => {
            console.log(err);
            // Dismiss the loader even if there's an error
            this.loader.dismiss();
          }
        );

      },
      err => {
        console.log(err);
        // Dismiss the loader even if the validation failed
        this.loader.dismiss();
      }
    );
  }


  onFileKBISSelected(event) {
    this.KBISfile = <File>event.target.files[0];
  }


  checkForErrors(obj, isInitialCall = true) {
    // Clear the errorMessages array only on the initial call
    if (isInitialCall) {
      this.errorMessages = [];
    }

    for (let key in obj) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        if (obj[key].hasOwnProperty('errors')) {
          // Assume errors property is an array and concatenate its contents to the errorMessages array
          this.errorMessages = this.errorMessages.concat(obj[key].errors);
        } else {
          // Recurse into nested objects, marking subsequent calls as recursive (not initial)
          this.checkForErrors(obj[key], false);
        }
      }
    }
  }

  async onSubmit(): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      // Removed the `resolver` as it's no longer needed.

      const formData = new FormData();
      formData.append('name', this.form.value.name)
      formData.append('type', this.form.value.type)
      formData.append('isSAPCertified', this.form.value.isSAPCertified)
      formData.append('SAPNumber', (this.form.value.SAPNumber ? this.form.value.SAPNumber : null))
      formData.append('SAPCertificationObtentionDate', (this.form.value.SAPCertificationObtentionDate ? this.form.value.SAPCertificationObtentionDate : ""))
      formData.append('registrationNumber', this.form.value.registrationNumber)
      formData.append('BIC', this.form.value.BIC)
      formData.append('KBIS[file]', this.KBISfile)
      formData.append('IBAN', this.form.value.IBAN)
      formData.append('coach', this.userInfos.profile.coach.uuid)

      console.log(formData);
      console.log(this.form.value.isSAPCertified)

      this.loader = await this.loadingController.create({ message: 'Enregistrement des données' });
      await this.loader.present();
      console.log('hello 4000');

      const handler = (res: any) => {
        console.log(res);
        resolve(true);
      };

      const errorHandler = (err: any) => {
        console.log(err);
        console.log(err.error.errors.children)
        this.checkForErrors(err.error.errors.children)
        console.log(this.errorMessages);
        this.loader.dismiss();
        resolve(false); // Use resolve instead of reject to fulfill the Promise<boolean> type.
      };

      if (this.coachInfos.companies.length !== 0) {
        console.log('Patch');
        this.companyService.patch(formData, this.coachInfos.companies[0].uuid).subscribe(handler, errorHandler);
      } else {
        console.log('Post');
        console.log(this.form.value.isSAPCertified);
        console.log(formData.get('isSAPCertified'));
        this.companyService.post(formData).subscribe(handler, errorHandler);
      }
    });
  }

  onPrevious(): void {
    this.location.back();
  }

  onSapYes(e) {
    this.sap = true
    this.form.get('SAPCertificationObtentionDate').enable()
    this.form.get('SAPCertificationObtentionDate').setValidators([Validators.required])
    this.form.get('SAPCertificationObtentionDate').updateValueAndValidity()
    this.form.get('SAPNumber').enable()
    this.form.get('SAPNumber').setValidators([Validators.required])
    this.form.get('SAPNumber').updateValueAndValidity()
  }

  onSapNo(e) {
    this.sap = false
    this.form.get('SAPCertificationObtentionDate').disable()
    this.form.get('SAPCertificationObtentionDate').updateValueAndValidity()
    this.form.get('SAPNumber').disable()
    this.form.get('SAPNumber').updateValueAndValidity()
  }


  // Form values storage when Form is not submitted yet
  nameFormTwo(e) {
    console.log(e.target.value)
  }

}
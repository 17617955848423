import { Component, ElementRef, ViewChild, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { timer } from 'rxjs';
import { ClientService } from 'src/app/services/api/client.service';
import { MessageService } from 'src/app/services/api/message.service';
import { ConversationIdService } from 'src/app/services/conversation-id.service';
import { SecurityService } from 'src/app/services/security.service';
import { LoadingController } from "@ionic/angular";
import { UserService } from 'src/app/services/api/user.service';


@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
})
export class MessagesComponent implements OnInit {
  @ViewChild('scrollMe') myDiv: any;
  //@ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll; 
  notRead = -1;
  timerSubsciption;
  read = -1;
  room;
  conversationInfos = {
    pageCount: 2
  };
  page = 1;
  pages;
  firstName = '';
  lastName = '';
  messages = [];
  actualPages;

  //
  conversation;
  conversationId;
  toFirstName = '';
  toLastName = '';
  conversationUuid;
  load;

  // userInfos
  userInfos;

  createDate(date) {
    return new Date(date);
  }

  isToday = (someDate) => {
    const today = new Date()
    return someDate.getDate() == today.getDate() &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear()
  }

  datesAreOnSameDay = (first, second) =>
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate();

  constructor(
    private route: ActivatedRoute,
    private clientService: ClientService,
    private messageService: MessageService,
    private security: SecurityService,
    private conversationIdService: ConversationIdService,
    private loadingController: LoadingController,
    private userService: UserService,
    private cdr: ChangeDetectorRef
  ) { }

  takeInformations(i) {
    if (this.conversation) {
      this.messageService.getAll(i).subscribe(
        res => {
          console.log("GET ALL :")
          this.pages = JSON.parse(JSON.stringify(res)).pageCount;
          if (JSON.parse(JSON.stringify(res)).items.find(element => element.uuid == this.conversation.uuid) == undefined && i < this.pages) {
            this.takeInformations(i + 1)
          }
          else {
            const array = JSON.parse(JSON.stringify(res)).items.find(element => element.uuid == this.conversation.uuid);
            console.log(array)
            this.lastName = array.client.profile.client.lastName;
            this.firstName = array.client.profile.client.firstName;
            console.log(res)
          }
        },
        err => {
          console.log(err)
        }
      )
    }
  }

  async ngOnInit() {
    this.conversationIdService.currentFirstName.subscribe(firstName => this.toFirstName = firstName)
    this.conversationIdService.currentLastName.subscribe(lastName => this.toLastName = lastName)
    this.conversationIdService.currentConversationUuid.subscribe(conversationUuid => this.conversationUuid = conversationUuid)
    this.conversationIdService.currentMessages.subscribe(messages => this.messages = messages)
    this.conversationIdService.currentLoad.subscribe(load => this.load = load)
    this.takeInformations(1);
    this.userService.getCurrent().subscribe(res => {
      console.log(res)
      this.userInfos = res
      this.reload()
    })

    // let loader = await this.loadingController.create()
    // await loader.present()
    // await loader.dismiss()
  }

  changeConversation(conversation) {
    this.conversation = conversation
    this.ngOnInit()
    // this.takeInformations(1)
    // this.reload()
  }

  //GET INDEX OF THE FIRST NEW MESSAGE
  newMessage(): number {
    let res = -1;
    for (let i = 0; i < this.messages.length; i++) {
      if (i == this.messages.length - 1 && (this.messages[i].isRead || this.messages[i].author.uuid == this.userInfos.uuid)) {
        return res;
      }
      else if (this.messages[i].isRead || this.messages[i].author.uuid == this.userInfos.uuid || this.messages[i + 1].author.uuid != this.userInfos.uuid) {
        return res;
      }
      res = i;
    }
  }


  reload() {
    this.timerSubsciption = timer(0, 5000).subscribe(() => {
      try {
        if (this.conversationUuid !== '') {
          this.readMessage();
        }
        this.reloadFunction(0);
      }
      catch (e) {
        console.log(e)
      }
    });
  }


  readMessage() {
    console.log("heeeeeeeeeeeeeeeey")
    console.log(this.messages)
    let index = -1;
    if (this.messages.length != 0) {
      console.log(this.messages[this.messages.length - 1])
      for (let i = this.messages.length - 1; this.messages[i].isRead && i > 0; i--) {
        index = i;
      }
      console.log(index)
      if (index == -1) {
        return
      }
    }

    console.log('hey message service')
    console.log(this.conversation)
    this.messageService.readMessage(this.conversationUuid).subscribe(
      res => {
        console.log("READ ?")
        console.log(res)
      },
      err => {
        console.log(err)
      }
    );
  }

  reloadFunction(i) {
    if (this.conversationUuid) {
      this.messageService.getPage(this.conversationUuid, i + 1).subscribe(
        res => {
          // hide loader
          // function with a display none because *ngIf is too slow
          if (this.messages[0]) {
            // this.hideloader()
            // this.load = false
          }
          this.load = false

          console.log("GET PAGE :")
          this.conversationInfos = JSON.parse(JSON.stringify(res))
          this.pages = JSON.parse(JSON.stringify(res)).pageCount;
          this.actualPages = JSON.parse(JSON.stringify(res)).currentPageNumber;

          for (let j = i * 20; j < i * 20 + JSON.parse(JSON.stringify(res)).items.length; j++) {
            this.messages[j] = JSON.parse(JSON.stringify(res)).items[j - (i * 20)];
          }

          this.notRead = this.newMessage()
          if (this.actualPages < this.pages) {
            this.reloadFunction(i + 1)
          }
          console.log(res)
        },
        err => {
          console.log(err)
        }
      )
    }
  }

  hideloader() {
    this.load = false
    // document.getElementById('loading').style.display = 'none';
  }

  sendMessage() {
    console.log((<HTMLInputElement>document.getElementById('messageToSend')).value)
    if ((<HTMLInputElement>document.getElementById('messageToSend')).value == '') {
      return
    }
    const body = {
      author: this.userInfos.uuid,
      message: (<HTMLInputElement>document.getElementById('messageToSend')).value,
      room: this.conversationUuid
    }

    this.messageService.sendMessage(this.conversationUuid, body).subscribe(
      res => {
        console.log("SENT ?")
        console.log(res)
        //UPDATE
        setTimeout(() => {
          this.messageService.getPage(this.conversationUuid, 1).subscribe(
            res => {
              console.log("RELOAD")
              this.messages = JSON.parse(JSON.stringify(res)).items
              console.log(res)
            },
            err => {
              console.log(err)
            }
          );
        }
          , 500)
      },
      err => {
        console.log(err)
      }
    );
    console.log(body);
    (<HTMLInputElement>document.getElementById('messageToSend')).value = '';
  }


  ngOnDestroy() {
    if (this.timerSubsciption) {
      this.timerSubsciption.unsubscribe();
    }
  }
}

import { ChangeDetectorRef, Component, Inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClientService } from 'src/app/services/api/client.service';
import { MessageService } from 'src/app/services/api/message.service';
import { SecurityService } from 'src/app/services/security.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataService } from "src/app/services/data.service";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConversationIdService } from 'src/app/services/conversation-id.service';
import { MessagesComponent } from '../messages/messages.component';
import { UserService } from 'src/app/services/api/user.service';

export interface DialogData {
  conversations;
}

@Component({
  providers: [MessagesComponent],
  selector: 'app-conversations',
  templateUrl: './conversations.component.html',
  styleUrls: ['./conversations.component.scss'],
})
export class ConversationsComponent implements OnInit {
  @Input() childMessage;

  conversations: any[] = [];
  possibleConversations = [];

  pages;
  actualPages;

  morePages = 1;

  //
  conversationId;
  messages;

  //
  userInfos;
  coachName;

  constructor(
    private security: SecurityService,
    private messageService: MessageService,
    private router: Router,
    private clientService: ClientService,
    public dialog: MatDialog,
    private data: DataService,
    private formBuilder: FormBuilder,
    private conversationIdService: ConversationIdService,
    private messagesComponent: MessagesComponent,
    private userService: UserService,
    private ref: ChangeDetectorRef
  ) { }

  ngOnInit() {
    // this.conversationIdService.currentConversationId.subscribe(conversationId => this.conversationId = conversationId)
    this.userService.getCurrent().subscribe(res => {
      console.log(res)
      this.userInfos = res;
      this.coachName = res.profile.coach.firstName
      this.reload();
    })

    // this.messageService.ge

    console.log(this.conversations.length)

    const searchbar = document.querySelector('ion-searchbar');

    let items = Array.from(document.querySelector('#conversations').querySelectorAll('.conversations'));
    searchbar.addEventListener('ionInput', handleInput);

    function handleInput(event) {
      const query = event.target.value.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      requestAnimationFrame(() => {
        items = Array.from(document.querySelector('#conversations').querySelectorAll('.conversations'));
        items.forEach((item) => {
          const shouldShow = item.querySelector('.names > div > .firstName').textContent.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(query) > -1 || item.querySelector('.names > div > .lastName').textContent.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(query) > -1;
          (<HTMLElement>item).style.display = shouldShow ? 'flex' : 'none';
        });
      });
    }
  }

  //OUVRIR LE POPUP
  openDialog(): void {
    const dialogRef = this.dialog.open(DialogNewConversationCoach, {
      width: '500px',
      data: {
        conversations: this.conversations,
        possibleConversations: this.possibleConversations,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.reload();
    });
  }

  addMorePages() {
    this.morePages += 1;
  }

  goToMessages(conversation, isAdmin = false) {
    console.log(typeof (conversation))
    console.log(conversation)

    if (!isAdmin) {
      let firstName = conversation.client.profile.client.firstName
      let lastName = conversation.client.profile.client.lastName
      let conversationUuid = conversation.uuid
      let messages = []
      let load = true
      this.conversationIdService.setRoomData(firstName, lastName, conversationUuid, messages, load)
    } else {

    }

    this.messagesComponent.changeConversation(conversation)
  }

  //OUVRIR UNE CONVERSATION
  getRooms(i) {
    console.log(2)
    this.messageService.getAll(i + 1).subscribe(
      res => {
        console.log(3)
        console.log("ROOOMS")
        console.log(res)
        console.log(JSON.parse(JSON.stringify(res)).items)

        if (i == 0) {
          this.conversations = [];
        }
        this.conversations = this.conversations.concat(JSON.parse(JSON.stringify(res)).items);
        console.log(this.conversations)
        this.pages = JSON.parse(JSON.stringify(res)).pageCount;
        this.actualPages = JSON.parse(JSON.stringify(res)).currentPageNumber;

        for (let j = i * 20; j < i * 20 + JSON.parse(JSON.stringify(res)).items.length; j++) {
          this.messageService.unreadMessages(JSON.parse(JSON.stringify(res)).items[j - i * 20].uuid).subscribe(
            res => {
              this.conversations[j]["newMessages"] = res
            },
            err => {
              console.log(err)
            }
          )
        }

        if (this.actualPages < this.pages) {
          this.getRooms(i + 1)
        }
        this.ref.detectChanges()
      },
      err => {
        console.log(err)
      }
    )
  }

  reload() {
    console.log(1)
    this.getRooms(0)

    this.clientService.getAllRooms(this.userInfos.uuid).subscribe(
      res => {
        console.log('lessons : ')
        console.log(res);
        console.log(JSON.parse(JSON.stringify(res)).items)
        this.possibleConversations = JSON.parse(JSON.stringify(res)).items
          .filter(el => !this.conversations
            .find(element => element.client.uuid == el.uuid))
      },
      err => {
        console.log(err)
      }
    )
  }
}


@Component({
  selector: 'dialog-new-conversation',
  templateUrl: 'dialog-new-conversation.html',
  styleUrls: ['./conversations.component.scss'],
})

export class DialogNewConversationCoach {
  constructor(
    public dialogRef: MatDialogRef<DialogNewConversationCoach>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private messageService: MessageService,
    private security: SecurityService,
    private router: Router
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  createRoom(uuid) {
    const body = {
      coach: this.security.user.uuid,
      client: uuid
    }
    this.messageService.createRoom(body).subscribe(
      res => {
        console.log('Room created : ')
        console.log(res);
        // this.router.navigate(['/m-coach-messages/' + res.uuid]);//A REVOIR SELON LA REPONSE
      },
      err => {
        console.log(err)
      }
    )
    console.log("Uncomment pour que la room se crée")
    console.log(body)
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/api/user.service';
import { SecurityService } from 'src/app/services/security.service';
import { ModalMentionsLegalesComponent } from '../modal/modal-mentions-legales/modal-mentions-legales.component';
import { MatDialog } from '@angular/material/dialog';
import { LoadingController } from '@ionic/angular';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss'],
})

export class EmailComponent implements OnInit {
  form: FormGroup;
  passwordShown: boolean = false;
  confirmPasswordShown: boolean = false;



  frontErrorMsg;
  backErrorMsg;

  private loader: HTMLIonLoadingElement;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private security: SecurityService,
    public dialog: MatDialog,
    private loadingController: LoadingController
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      plainPassword: this.formBuilder.group({
        first: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(12)]],
        second: ['', [Validators.required]],
      })
    })
  }

  samePassword(e) {
    if (e.target.value === this.form.value.plainPassword.first) {
      this.frontErrorMsg = ""
    }
  }

  openLegal() {
    const dialogRef = this.dialog.open(ModalMentionsLegalesComponent, {
      width: '550px',
      height: '80vh',
      autoFocus: false
    });
  }

  email() {
    this.backErrorMsg = ""
  }

  async onSubmit() {
    if (this.form.valid) {
      if (this.form.value.plainPassword.first !== this.form.value.plainPassword.second) {
        this.frontErrorMsg = "Vos mots de passe doivent être identiques"
        return
      }
      const body = {
        email: this.form.value.email,
        plainPassword: this.form.value.plainPassword,
      };
      console.log('body', body)
      // this.loader = await this.loadingController.create({ message: 'Connexion' })
      this.loadingController.create({ message: 'Connexion' }).then(loader => {
        loader.present()

        this.userService.register(body).subscribe(res => {
          console.log(res)
          this.security.loginConnectCoach({ username: body.email, password: body.plainPassword.first }).subscribe(
            value => {
              console.log('value', value)
              loader.dismiss()
              this.router.navigate(['/coach-inscription-profile'])
            },
            err => {
              console.log('erreur', err)
              loader.dismiss()
            }
          )
        },
          err => {
            console.log(err)
            console.log(err.error.errors.children.email.errors[0])
            if (err.error.errors.children.email.errors[0] = 'Un compte associé à cet email existe déjà') {
              this.backErrorMsg = err.error.errors.children.email.errors[0]
            }

            this.security.loginConnectCoach({ username: body.email, password: body.plainPassword.first }).subscribe(
              value => {
                console.log('value', value)
                loader.dismiss()
                this.router.navigate(['/coach-inscription-profile'])
              },
              err => {
                console.log('erreur', err)
                loader.dismiss()
              }
            )

            loader.dismiss()
          }
        )
      })
    }
  }

  togglePasswordVisibility() {
    this.passwordShown = !this.passwordShown;
  }

  toggleConfirmPasswordVisibility() {
    this.confirmPasswordShown = !this.confirmPasswordShown;
  }
}
